.icon-search-wrapper {
  max-width: 300px;
}
.icon-search-wrapper .feather-search {
  height: 1.15rem;
  width: 1.15rem;
}
#icons-container .icon-card {
  width: 128px;
}
[dir] .icon-card {
  border: 1px solid transparent;
}
[dir] .icon-card.active {
  border-color: #0875d6;
}
.icon-card.active i,
.icon-card.active svg {
  color: #0875d6;
}
@media (max-width: 1024px) {
#icons-container .icon-card {
    width: 126px;
}
}
@media (max-width: 768px) {
#icons-container .icon-card {
    width: 131px;
}
}
@media (max-width: 414px) {
#icons-container .icon-card {
    width: 110px;
}
}
@media (max-width: 375px) {
#icons-container .icon-card {
    width: 150px;
}
}